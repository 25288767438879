<template>
  <div class="page page__content-wrapper">
    <div class="page__min-container">
      <div class="top-block page__top-block">
        <img class="top-block__image" src="../../../assets/img/typography-page/typography-01.png" alt="">

        <div class="top-block__text text__normal">
          Стапель — современный закрытый гротеск с техничными и простыми формами и&nbsp;акцентом на&nbsp;тонких
          примыканиях штрихов. В большинстве начертаний есть альтернативные алфавитные знаки, пропорциональные и
          моноширинные цифры и&nbsp;математические символы, дроби, знаки валют, case-зависимая пунктуация.
        </div>
      </div>
    </div>

    <div class="page__full-width">
      <div class="typo-block" v-for="(item, idx) in fonts" :key="idx">
        <div class="typo-block__wrapper">
          <typography-item :item="item"></typography-item>
        </div>
        <div class="page__min-container">
          <div class="typo-block__btn" v-if="item.isDownload">
            <ButtonDownload
              :text-button="item.download.text"
              :url="item.download.url"
              :icons="item.download.icons"
              class="page__download-block"
            />
          </div>
          <div class="typo-block__border" v-if="item.isBorder"></div>
        </div>
      </div>

    </div>

<!--    <div class="page__full-width">-->
<!--      <typography-item v-for="(item, idx) in fontsSecond" :item="item" :key="idx"></typography-item>-->
<!--    </div>-->

    <bottom-nav-block :prevLink="bottomNav.pre" :nextLink="bottomNav.next"/>
  </div>
</template>

<script>
import BottomNavBlock from "@/components/Elements/BottomNavBlock/BottomNavBlock"
import TypographyItem from "../../../components/Elements/TypographyItem/TypographyItem"
import ButtonDownload from "../../../components/Elements/ButtonDownload/ButtonDownload"

export default {
  components: {ButtonDownload, TypographyItem, BottomNavBlock},
  data() {
    return {
      bottomNav: {
        pre: {
          url: "/identity/color",
          text: "Цвета"
        },
        next: {
          url: "/identity/style",
          text: "Фирменный стиль"
        }
      },
      fonts: [
        {
          title: "Акциденция",
          isDownload: true,
          isBorder: true,
          description: [
            "Для заголовков, акцентного текста, набора адреса сайта"
          ],
          fonts: [
            {
              name: "Stapel Semi Expanded Medium",
              text: "typography-page/stapel/stapel-01.svg",
              icons: [
                {
                  url: "../download-file/02-Typography/Stapel_Semi-Expanded-Medium.otf",
                  icon: "icon-otf.svg"
                },
                {
                  url: "../download-file/02-Typography/Stapel_Semi-Expanded-Medium.woff",
                  icon: "icon-woff.svg"
                }
              ]
            },
            {
              name: "Stapel Medium",
              text: "typography-page/stapel/stapel-02.svg",
              icons: [
                {
                  url: "../download-file/02-Typography/Stapel_Medium.otf",
                  icon: "icon-otf.svg"
                },
                {
                  url: "../download-file/02-Typography/Stapel_Medium.woff",
                  icon: "icon-woff.svg"
                }
              ]
            },
            {
              name: "Stapel Regular",
              text: "typography-page/stapel/stapel-03.svg",
              icons: [
                {
                  url: "../download-file/02-Typography/Stapel_Regular.otf",
                  icon: "icon-otf.svg"
                },
                {
                  url: "../download-file/02-Typography/Stapel_Regular.woff",
                  icon: "icon-woff.svg"
                }
              ]
            },
            {
              name: "Stapel Light",
              text: "typography-page/stapel/stapel-04.svg",
              icons: [
                {
                  url: "../download-file/02-Typography/Stapel_Light.otf",
                  icon: "icon-otf.svg"
                }
              ]
            }
          ],
          download: {
            url: "../download-file/02-Typography/Stapel.zip",
            text: "Скачать семейство",
            icons: [
              {
                url: "",
                icon: "otf.svg"
              },
              {
                url: "",
                icon: "ttf.svg"
              },
              {
                url: "",
                icon: "woff.svg"
              }
            ]
          }
        },
        {
          title: "Текстовые блоки",
          isDownload: true,
          isBorder: true,
          description: [
            "Набор начертаний, специально созданный для работы с большими объемами текста в малых и средних кеглях."
          ],
          fonts: [
            {
              name: "Stapel Text Medium",
              text: "typography-page/stapel-text/stapel-text-01.svg",
              icons: [
                {
                  url: "../download-file/02-Typography/Stapel_Text-Medium.otf",
                  icon: "icon-otf.svg"
                }
              ]
            },
            {
              name: "Stapel Text Regular",
              text: "typography-page/stapel-text/stapel-text-02.svg",
              icons: [
                {
                  url: "../download-file/02-Typography/Stapel_Text-Regular.otf",
                  icon: "icon-otf.svg"
                }
              ]
            },
            {
              name: "Stapel Text Light",
              text: "typography-page/stapel-text/stapel-text-03.svg",
              icons: [
                {
                  url: "../download-file/02-Typography/Stapel_Text-Light.otf",
                  icon: "icon-otf.svg"
                }
              ]
            }
          ],
          download: {
            url: "../download-file/02-Typography/Stapel-Text.zip",
            text: "Скачать семейство",
            icons: [
              {
                url: "",
                icon: "otf.svg"
              },
              {
                url: "",
                icon: "ttf.svg"
              }
            ]
          }
        },
        {
          title: "Дополнительный шрифт",
          isDownload: true,
          isBorder: false,
          description: [
            "Применяется во внутрикорпоративных презентациях и во всех других случаях, когда установка шрифта Stapel и Stapel Text на используемый для создания макета компьютер невозможна или нецелесообразна.",
            "Также шрифт PT Root IU используется для дизайна интерфейсов, а также разметки технической информации HUD-элементов (описаны в разделе <a href='/identity/style' class='last-word'>Фирменный Стиль</a>)"
          ],
          fonts: [
            {
              name: "PT Root UI Light",
              text: "typography-page/pt-root/pt-root-01.svg",
              icons: [
                {
                  url: "../download-file/02-Typography/PT-Root-UI_Light.otf",
                  icon: "icon-otf.svg"
                },
                {
                  url: "../download-file/02-Typography/PT-Root-UI_Light.woff",
                  icon: "icon-woff.svg"
                }
              ]
            },
            {
              name: "PT Root UI Regular",
              text: "typography-page/pt-root/pt-root-02.svg",
              icons: [
                {
                  url: "../download-file/02-Typography/PT-Root-UI_Regular.otf",
                  icon: "icon-otf.svg"
                },
                {
                  url: "../download-file/02-Typography/PT-Root-UI_Regular.woff",
                  icon: "icon-woff.svg"
                }
              ]
            },
            {
              name: "PT Root UI Medium",
              text: "typography-page/pt-root/pt-root-03.svg",
              icons: [
                {
                  url: "../download-file/02-Typography/PT-Root-UI_Medium.otf",
                  icon: "icon-otf.svg"
                },
                {
                  url: "../download-file/02-Typography/PT-Root-UI_Medium.woff",
                  icon: "icon-woff.svg"
                }
              ]
            },
            {
              name: "PT Root UI Bold",
              text: "typography-page/pt-root/pt-root-04.svg",
              icons: [
                {
                  url: "../download-file/02-Typography/PT-Root-UI_Bold.otf",
                  icon: "icon-otf.svg"
                },
                {
                  url: "../download-file/02-Typography/PT-Root-UI_Bold.woff",
                  icon: "icon-woff.svg"
                }
              ]
            }
          ],
          download: {
            url: "../download-file/02-Typography/PT-Root-UI.zip",
            text: "Скачать семейство",
            icons: [
              {
                url: "",
                icon: "otf.svg"
              },
              {
                url: "",
                icon: "ttf.svg"
              },
              {
                url: "",
                icon: "woff.svg"
              }
            ]
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/page-block";
@import "src/assets/style/text-style";
@import "src/assets/style/var-style";

.top-block {
  &__image {
    margin-bottom: 45px;
  }

  &__text {
    max-width: 688px;
  }
}

.typo-block {

  &__wrapper {
    margin-bottom: 88px;
  }

  &__border {
    border-bottom: 2px solid #fff;
    margin-top: 96px;
    margin-bottom: 94px;
    //padding-bottom: 96px;
  }

  &__btn {
    margin-bottom: 96px;
  }
}

.page {
  margin-top: 96px;
  margin-bottom: 192px;

  &__min-container {
    img {
      width: 100%;
    }
  }

  &__top-block {
    margin-bottom: 96px;
  }
}

/deep/.main-btn_download {
  padding-right: 29px;
}

/deep/.last-word,
.last-word {
  color: inherit;
  text-decoration: underline;
}

@media screen and (max-width: 1540px) {
  .page__content-wrapper {
    padding-left: 64px;
  }
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 263px;
  }
}


@media screen and (max-width: 1320px) {
  .page__content-wrapper {
    padding-left: 0;
  }
}

@media screen and (max-width: 1216px) {
  .page__link-block.download-block {
    display: none;
  }
}

@media screen and (max-width: 1090px) {
  .page {
    margin-top: 0;
    padding-top: 18px;
  }
}

@media screen and (max-width: 896px) {
  .top-block {
    margin-bottom: 81px;

    &__image {
      border-radius: 4px;
    }

  }

  .typo-block {
    &__wrapper {
      margin-bottom: 71px;
    }

    &__border {
      margin-top: 71px;
      margin-bottom: 80px;
    }

    &__btn {
      display: none;
    }
  }

  .pages-wrap {
    .container {
      .page {
        &.page__content-wrapper {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }

  .page {
    padding-top: 48px;
    margin-bottom: 184px;

    &__full-width {
      width: 100%;
    }

    &__download-block {
      display: none;
    }
  }
}
</style>